export const mockUsers = [
    {
        email: 'jieff@gmail.com',
        password: '123'
    },
    {
        email: 'helio@gmail.com',
        password: '456'
    },
    {
        email: 'guilherme@gmail.com',
        password: '789'
    }
];
